import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useRef, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProvidersSwiper from "./ProvidersSwiper";
import PageBanner from "../PageBanner";
import ProvidersMobileSwiper from "./ProvidersMobileSwiper";

import { MdOutlineCasino } from "react-icons/md";
import { PiCoinsBold } from "react-icons/pi";
import FilterTypes from "../LiveCasino/FilterTypes";

import PragmaticGameBox from "../GamerBoxes/PragmaticGameBox";
import LiveGameBox from "../GamerBoxes/LiveGameBox";

import { useParams } from "react-router-dom";
import CategoryType from "./CategoryType";

const ScrollableListViewPPG = (props) => {
  const {
    user,
    loggedIn,
    sports,
    games,
    UpdateSport,
    isDirectLobby,
    pb,
    provider,
    setProvider,
    liveGameProviders,
    getMore,
    setPage,
    FilterSearchValue,
    setFilterSearchValue,
    title,
  } = props;
  const [FilterTypeValue, setFilterTypeValue] = useState(null);

  const [FilterTypeList, setFilterTypeList] = useState([]);
  const [data, setData] = useState([...props.data]);

  const [loading, setLoading] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [fabActive, setFabActive] = useState(false);

  const [visibleData, setVisibleData] = useState([]);
  const containerRef = useRef(null);

  const [acitveMenu, setActiveMenu] = useState("hot");

  const [plist, setPlist] = useState([]);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop > 300 && !fabActive) {
      setFabActive(true);
    }
    if (scrollTop < 300 && fabActive) {
      setFabActive(false);
    }

    if (scrollTop + clientHeight >= scrollHeight) {
      // User has scrolled to the bottom, load more data
      setVisibleData((prevData) => [
        ...prevData,
        ...data.slice(prevData.length, prevData.length + 20),
      ]); // Load next 10 items, adjust as needed
    }

    if (scrollTop > lastScrollTop) {
      setIsNavVisible(false);
    } else {
      setIsNavVisible(true);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };
  useEffect(() => {
    const _v = props.data.slice(0, 36);
    setVisibleData(_v); // Initial rendering, show first 10 items
  }, [props.data]);

  useEffect(() => {
    const _pList = providerPrioraty
      .map((provider) =>
        liveGameProviders.find((item) => item.provider === provider)
      )
      .filter((item) => item !== undefined)
      .filter((x) => {
        debugger;
        if (
          x.provider == "537" &&
          !props?.permissions?.PRAGMATIC_LIVECASINO_ACCESS?.Value
        ) {
          return false;
        } else if (
          x.provider == "668" &&
          !props?.permissions?.ABSOLUTE_LIVECASINO_ACCESS?.Value
        ) {
          return false;
        } else if (
          x.provider == "791" &&
          !props?.permissions?.EVOLUTION_LIVECASINO_ACCESS?.Value
        ) {
          return false;
        } else if (
          x.provider == "75" &&
          !props?.permissions?.EZUGI_LIVECASINO_ACCESS?.Value
        ) {
          return false;
        } else {
          return true;
        }
        //        props?.permissions?.PRAGMATIC_LIVECASINO_ACCESS?.Value;
      })
      .concat(
        liveGameProviders.filter(
          (item) => !providerPrioraty.includes(item.provider)
        )
      );
    debugger;
    setPlist(_pList);
  }, [liveGameProviders]);

  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1);
        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);

  const providerPrioraty = ["537", "668", "791", "75"];

  return (
    <Translation>
      {(t) => (
        <div>
          <div
            style={{ overflowY: "scroll" }}
            ref={containerRef}
            onScroll={handleScroll}
            className="pb-[20px] bg-secondary-500"
            onLoad={adjustHeight}
          >
            <PageBanner
              title={title}
              subTitle={t("SUBTITLE_BANNER_CASINO")}
              bg={pb}
              show={true}
            />
            {/*
   <section className=" pt-2 mobile-show ">
              <ProvidersMobileSwiper
                setPage={setPage}
                _data={[
                  ...liveGameProviders
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .reverse(),
                ]}
              />{" "}
            </section>
  */}

            <section className="py-2">
              <ProvidersSwiper setPage={setPage} data={plist} />
            </section>

            {/*
              <section className="mb-1">
              <FilterTypes
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
              />
            </section>
          */}
            {!isDirectLobby && (
              <section className="pb-4">
                <CategoryType
                  acitveMenu={acitveMenu}
                  setActiveMenu={(value) => {
                    if (value == "new") {
                      const new_data = props.data.sort(function (a, b) {
                        return b.gameIdNumeric - a.gameIdNumeric;
                      });
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    } else if (value == "hot") {
                      setData(props.data);
                      const _v = props.data.slice(0, 36);
                      setVisibleData(_v);
                    } else {
                      const new_data = props.data.filter((x) =>
                        x.gameName.toLowerCase().includes(value.toLowerCase())
                      );
                      setData(new_data);
                      const _v = new_data.slice(0, 36);
                      setVisibleData(_v);
                    }
                    setActiveMenu(value);
                  }}
                />
              </section>
            )}
            {!isDirectLobby && (
              <>
                {params?.ProviderId == "537" ? (
                  <div className="px-1 grid grid-cols-3   sm:grid-cols-4  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6  2xl:grid-cols-8 gap-2 md:gap-4">
                    {visibleData.map((item, index) => (
                      <PragmaticGameBox
                        key={item.gameIdNumeric + "-" + index}
                        isLive={true}
                        index={index}
                        item={item}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="px-1 grid grid-cols-3   sm:grid-cols-4  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6  2xl:grid-cols-9 gap-2 md:gap-4">
                    {visibleData.map((item, index) => (
                      <LiveGameBox
                        index={index}
                        item={item}
                        provider={provider}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          {fabActive && (
            <button
              className="btn btn-primary text-white btn-circle fixed right-5 bottom-20 shadow-xl fade-in"
              onClick={() => {
                const scl = containerRef.current;
                scl.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                />
              </svg>
            </button>
          )}

          <div className=" mobile-show">
            <div
              style={{ visibility: isNavVisible ? "visible" : "hidden" }}
              className="btm-nav  border-t border-primary-500 bg-gradient-to-r from-primary-700 via-primary-600 to-primary-700 drop-shadow-2xl shadow-2xl"
            >
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/sports"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_SPORTS")}
                    </span>
                  </div>
                </Link>
              )}
              <Link
                to={"/casino"}
                className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <MdOutlineCasino className="w-5 h-5" />
                  <span className="btm-nav-label text-xs">
                    {t("TITLE_CASINO")}
                  </span>
                </div>
              </Link>
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/my-account/coupons"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_MYBETS")}
                    </span>
                  </div>
                </Link>
              )}

              {props.permissions?.LIVECASINO_ACCESS?.Value && (
                <Link
                  to={"/live-casino"}
                  className="text-white max-w-[5rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <PiCoinsBold className="w-5 h-5" />
                    <span className="btm-nav-label text-xs">
                      {t("TITLE_LIVECASINO")}
                    </span>
                  </div>
                </Link>
              )}

              <Link
                to={"/my-account/profile/"}
                className="text-white max-w-[5rem] bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>

                  <span className="btm-nav-label text-xs">
                    {t("TITLE_MYACCOUNT")}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions } = authentication;
  const { sports, games, liveGameProviders } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    liveGameProviders,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollableListViewPPG);

export const GetGamePoster = (cat, data) => {
  switch (cat) {
    case "pragmatic-casino":
      return `https://common-static.ppgames.net/game_pic/square/200/${data.gameID}.png`;
    case "smartsoft":
      return `https://maxbetone.s3.eu-central-1.amazonaws.com/images/smartsoft/${data.GameName}.png`.toLowerCase();
    default:
      break;
  }
};
